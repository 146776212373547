import React, {useState, useEffect, useRef, useCallback} from "react";
import "./AIOutputChat.css";
import "../../pages/ChatPage.css";
import {MessageButtonSelection} from "./MessageButtonSelection";
import {getUserAccessToken, getUserID, supabase, userIDExists} from "../../App";
// import {WaveSurfer} from "./WaveSurfer";
import {ReactComponent as PlayButton} from "./ChatIcons/playicon.svg";
import {ReactComponent as PauseButton} from "./ChatIcons/pause.svg";
import {

    BeatLoader, BounceLoader
} from 'react-spinners';
// import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import {ReactComponent as FullScreen} from "../../icons/fullscreen.svg"
import {ReactComponent as ThumbsUpIcon} from "../../icons/thumbs-up.svg";
import {ReactComponent as ThumbsDownIcon} from "../../icons/thumbs-down.svg";
import {ReactComponent as DiamondIcon} from "../../icons/diamond.svg";
// import {ReactComponent as ResumeMessage} from "../../icons/resume.svg";
import ReportDislikeButton from "./ReportDislikeButton";
import UpgradeNotification from "../Notifications/UpgradeNotification";
import {useNavigate} from "react-router-dom";
import {ChatFuncContext} from "../Context/ChatFunctionsContext";
import {useProfileData} from "../Context/ProfileDataContext";
import {useWavesurfer} from "@wavesurfer/react";
import {useChat} from "../Context/ChatContext";
import {useTimer} from "../Context/TimerContext";


//TODO: Ensure dynamic loading focusing on the correct type of the image expected.
const AIOutputChat = ({
                          text,
                          messageId,
                          imageUrl,
                          regenMessage,
                          isLatest,
                          isFirst,
                          deleteMessage,
                          profileUrl,
                          senderUsername,
                          characterUsername,
                          optionalUsername,
                          optionalTimestamp,
                          optionalCharacterId,
                          isActuallyUser,
                          senderId,
                          continueMessage,
                          areButtonsVisible,
                          setAreButtonsVisible,
                          likeStatus, audioUrl,
                          currentCharacterType,
                          generateImageExternal,
                          generateAudioExternal,
                          userMessage,
                          responseMode
                      }) => {

    console.log(responseMode)
    const [loading, setLoading] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 680);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 680);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const {
        isGalleryBannerVisible,
    } = useChat();

    const {
        setContextualMessageID,
        setContextualImageURL,
        setContextualImageLoading,
        contextualMessageID,
        contextualImageURL,
        contextualImageLoading,
        setContextualAudioID,
        setContextualAudioURL,
        setContextualAudioLoading,
        contextualAudioID,
        contextualAudioURL,
        contextualAudioLoading
    } = ChatFuncContext();


    const loadingRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [imageURL, setImageURL] = useState(imageUrl);
    const [imageActionDisabled, setImageActionDisabled] = useState(true);
    const [imageLoading, setImageLoading] = useState(false);
    const [audioLoading, setAudioLoading] = useState(false);
    const [audioURL, setAudioURL] = useState(audioUrl);
    const [audioEnabled, setAudioEnabled] = useState(true);

    useEffect(() => {
        if (audioURL !== undefined && audioURL.length > 0) {
            setAudioEnabled(false);
        }
    }, [audioURL]);

    useEffect(() => {
        if (messageId === undefined || messageId === null) {
            return;
        }
        if (userMessage !== undefined && userMessage !== null && userMessage.length > 0) {
            const lowercaseText = userMessage.toLowerCase();
            if (lowercaseText.includes("send me") || lowercaseText.includes("sendme")
                || lowercaseText.includes("can i see") || lowercaseText.includes("envision me")
                || lowercaseText.includes('envisionme')) {
                if (lowercaseText.includes("audio") || lowercaseText.includes("voice")) {
                    generateAudio().then(r => {
                    });
                } else {
                    generateImage().then(r => {
                    });
                }
            } else if (lowercaseText.includes("voice message") || lowercaseText.includes("i want to hear you")) {
                generateAudio().then(r => {
                });
            }
            if (Math.floor(Math.random() * 25) === 1 && isLatest && profileData.subscription_data.tier === 'FREE') {
                // Random chance of 1 in 2
                if (Math.floor(Math.random() * 2) === 1) {
                    generateImage().then(r => {
                    });
                } else {
                    generateAudio().then(r => {
                    });
                }
            }
        }
        //eslint-disable-next-line
    }, [text, messageId]);


    useEffect(() => {
        if (isLatest) {
            if (messageId !== undefined && messageId !== null) {
                setContextualMessageID(messageId);
            }
            if (imageUrl !== undefined && imageUrl !== null) {
                setContextualImageURL(imageUrl);
            }
            if (imageLoading !== undefined && imageLoading !== null) {
                setContextualImageLoading(imageLoading);
            }
        }
        if (messageId === contextualMessageID && contextualImageLoading) {
            if (imageUrl === null || imageUrl === undefined || imageUrl.length === 0) {
                setImageLoading(true);
            }
        } else if (messageId === contextualMessageID && !contextualImageLoading && contextualImageURL.length > 0) {
            setImageURL(contextualImageURL);
            setImageLoading(false);
            setContextualImageLoading(false);
        }
        // TODO: Add leftover hooks here without breaking it
        //eslint-disable-next-line
    }, [messageId, imageUrl, imageLoading, isLatest, contextualImageLoading, contextualImageURL, contextualMessageID]);

    useEffect(() => {
        if (audioUrl !== undefined && audioUrl !== null && audioUrl.length > 0) {
            return;
        }
        if (isLatest) {
            if (messageId !== undefined && messageId !== null && !contextualAudioLoading) {
                setContextualAudioURL('');
                setContextualAudioID(messageId);
            }
            if (audioUrl !== undefined && audioUrl !== null && audioUrl.length > 0) {
                setContextualAudioURL(audioUrl);
            }
            if (audioLoading !== undefined && audioLoading !== null && !contextualAudioLoading) {
                setContextualAudioLoading(audioLoading);
            }
        }
        if (messageId === contextualAudioID && contextualAudioLoading) {
            if (audioUrl === null || audioUrl === undefined || audioUrl.length === 0) {
                setAudioLoading(true);
            }
        } else if (messageId === contextualAudioID && !contextualAudioLoading && contextualAudioURL.length > 0) {
            setAudioURL(contextualAudioURL);
            setAudioLoading(false);
            setContextualAudioLoading(false);

        }
        //eslint-disable-next-line
    }, [messageId, audioUrl, audioLoading, isLatest, contextualAudioLoading, contextualAudioURL, contextualAudioID]);


    useEffect(() => {
        setImageActionDisabled(!(!!imageUrl && imageUrl.length > 0));
    }, [imageUrl]);


    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 500);
        return () => clearTimeout(timer);


    }, []);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const getMessageId = () => {
        return messageId;
    }

    const hasImage = () => {
        if (imageURL === undefined) return false;
        return !isActuallyUser && imageURL.length > 0;
    }

    const hasAudio = () => {
        return audioURL !== undefined && audioURL.length > 0;
    }

    const [isContinuing, setIsContinuing] = useState(false);

    const handleContinueMessage = async () => {
        const canContinue = messageId !== null && messageId !== undefined && (await userIDExists());

        if (canContinue) {
            setIsContinuing(true);
            const identityName = window.location.pathname.substring(6);
            const userId = await getUserID();
            const {data: character} = await supabase
                .from('characters')
                .select('id')
                .eq('user_id', userId)
                .eq('identity_name', identityName)
                .single();

            await continueMessage(character.id, text, messageId, identityName, userId)
            setIsContinuing(false);
        }
    }

    const [currentImage, setCurrentImage] = useState(null);

    const generateImage = async () => {
        const resp = await generateImageExternal(setImageLoading, optionalTimestamp, optionalCharacterId, setImageURL, messageId);

        if (resp !== undefined) {
            if (resp.errorCode === 50) {
                const blurredImage = resp.blurred_image;
                setLikeStatus(3);
                setCurrentImage(blurredImage);
                setImageURL(blurredImage)
                setImageLoading(false);
                return;
            }
        }
        setLikeStatus(0);
        setContextualImageLoading(false);
    }


    const generateAudio = async () => {
        await generateAudioExternal(setAudioLoading, optionalTimestamp, optionalCharacterId, setAudioURL, messageId);
        setContextualAudioLoading(false);
    };

    const WaveContainerRef = useRef(null);

    const {wavesurfer, isPlaying} = useWavesurfer({
        container: WaveContainerRef,
        height: 30, // Set the height to 40px
        waveColor: 'rgb(255,255,255)',
        progressColor: 'rgb(164,137,236)',
        url: audioURL,
        barGap: 2,
        barWidth: 4,
        barRadius: 2,
        normalize: true,
        fillParent: true,
        autoCenter: true,
    });

    const onPlayPause = useCallback(() => {
        wavesurfer && wavesurfer.playPause();
    }, [wavesurfer]);

    useEffect(() => {
        if (wavesurfer && audioURL) {
            wavesurfer.load(audioURL);
        }
    }, [wavesurfer, audioURL]);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!imageLoading) return; // Only proceed if loading is true

        setProgress(0); // Reset to 0 every time an image request starts
        const totalDuration = 25000; // 10 seconds total duration bruh

        const interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }

                const nextProgress = prevProgress + 1;

                return nextProgress;
            });
        }, totalDuration / 100);

        return () => clearInterval(interval);
    }, [imageLoading]);

    const regenerateMessage = async () => {
        if (optionalTimestamp !== undefined && optionalCharacterId !== undefined) {
            await regenMessage(optionalCharacterId);
        } else {
            await regenMessage("", profileUrl ?? "", true, messageId);
        }
    }

    const deleteMessageWrapper = async () => {
        if (optionalTimestamp !== undefined && optionalCharacterId !== undefined) {
            if (isLatest) {
                return await deleteMessage(optionalCharacterId);
            }
        } else {
            return await deleteMessage(messageId);
        }
    }

    function formatMessage(message, characterUsername, username) {
        if (optionalCharacterId !== undefined && optionalCharacterId.length > 0) return message;

        // if (responseMode === "ElyzaMint") {
        //     const regex = /\*\*.*?\*\*|\*[^*\n]+\*/g;
        //
        //     message = message.replace(regex, '');
        // }

        // Trim the message and replace multiple consecutive newline characters with a single newline character
        message = message.trim().replace(/\n+/g, '\n');

        // Replace {{char}} and {{user}} placeholders
        message = message.replace(/{{char}}/gi, characterUsername)
            .replace(/{{user}}/g, username)
            // .replace(/user/g, username)
            .replace(/{char}/g, characterUsername)
            .replace(/{user}/g, username)
            .replace(/{Char}/g, characterUsername)
            .replace(/{User}/g, username);

        // Remove curly braces from the entire message
        message = message.replace(/[{}]/g, '');

        // Remove all types of quotes from the entire message, including nested and partial quotes
        message = message.replace(/["`”“]/g, '');

        // Add a newline before ** sections and format non-speech text (enclosed in * or **) as bold and italic
        message = message.replace(/(\s*)(\*\*?)([^*\n]+)\2/g, (match, space, stars, content) => {
            let formattedText = '\n\n<b><i>' + content.trim() + '</i></b>';
            if (!/[.!?]$/.test(content)) {
                formattedText += '.';
            }
            return formattedText + '\n\n';
        });

        // Remove any triple newlines that might have been created
        message = message.replace(/\n{3,}/g, '\n\n');

        return message.trim();
    }


    const containerClass = window.innerWidth >= 680 && isGalleryBannerVisible === true ? "bot-chat-container wide" : "bot-chat-container";

    const [isFullscreen, setIsFullscreen] = useState(false);

    const openImageInFullScreen = (imageElement) => {
        if (likeStatus === 3 || profileData.subscription_data.tier === 'FREE') {
            return;
        }
        if (likeStatus !== 3) { // Add this condition
            if (!document.fullscreenElement) {
                if (imageElement.requestFullscreen) {
                    imageElement.requestFullscreen().then(() => {
                        setIsFullscreen(true);
                    }).catch(err => {
                        console.error(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
                    });
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen().then(() => {
                        setIsFullscreen(false);
                    }).catch(err => {
                        console.error(`Error attempting to disable fullscreen mode: ${err.message} (${err.name})`);
                    });
                }
            }
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullscreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
    }, []);

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoaded = () => {
        setImageLoaded(true);
    };

    const [reportFormMessage, setReportFormMessage] = useState('');

    const handleShortDescriptionChange = (e) => {
        setReportFormMessage(e.target.value);
    }

    const reportNegativeImageQuality = async (imageURL) => {
        setLikeStatus(2);
        setReportModalOpen(false);

        const resp = await fetch('https://api.elyza.app/v1/report-image-quality', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                imageUrl: imageURL, isPositive: false, issueReport: reportFormMessage,
            })
        });

        if (resp.status === 200) {

        }
    }

    const reportPositiveImageQuality = async (imageURL) => {

        setLikeStatus(1);

        const resp = await fetch('https://api.elyza.app/v1/report-image-quality', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                imageUrl: imageURL, isPositive: true, issueReport: '',
            })
        });

        if (resp.status === 200) {

        }
    }

    const [isReportModalOpen, setReportModalOpen] = useState(false);

    const openReportModal = () => {
        setReportModalOpen(true);
    };

    const [cacheLikeStatus, setLikeStatus] = useState(likeStatus);

    const handleLikeClick = (e) => {
        if (!hasImage() || cacheLikeStatus !== 0) {
            return;
        }

        // TODO: FORM WITH REPORT MESSAGE
        e.stopPropagation();

        setLikeStatus(1);

        reportPositiveImageQuality(imageURL).then(() => {
        });
    };

    const handleDislikeClick = (e) => {
        if (!hasImage() || cacheLikeStatus !== 0) {
            return;
        }

        e.stopPropagation();

        openReportModal();
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            loadingRef.current?.scrollIntoView({behavior: "smooth"});
        }
    }, [loadingRef, loading]);


    const handleRemoveBlurClick = () => {
        setSubscriptionError(true); // Assuming this triggers your pop-up
    };

    const {profileData} = useProfileData();

    const { timerExpired } = useTimer();

    return (

        <div className="chat-message-bot-h" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={containerClass}
                 style={{marginBottom: timerExpired !== false ? "20px" : '40px'}}
            >
                <div className={'ai-chat-message-main'}>

                    {text.length === 0 && (
                        <div
                            className="message-loading-spin"
                        >
                            <BeatLoader color="white"/>
                        </div>
                    )}

                    <div className="bot-text">
                        {formatMessage(text, characterUsername, senderUsername).split('\n').map((line, index, array) => {
                            const isLastLine = index === array.length - 1;
                            return (
                                <React.Fragment key={index}>
                                    <span dangerouslySetInnerHTML={{__html: line}}/>
                                    {!isLastLine && <br style={{lineHeight: '0.5', margin: '0.25em 0'}}/>}
                                </React.Fragment>
                            );
                        })}
                        {audioLoading ? (
                            <div className="message-loading-spin"
                                 style={{
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     marginTop: '20px'
                                 }}
                            >
                                <BeatLoader color="white"/>
                            </div>
                        ) : (
                            audioURL && (
                                <div className='surfer-h-container'>
                                    <button onClick={onPlayPause} className='surfer-control-timer'>
                                        {isPlaying ? <PauseButton/> : <PlayButton/>}
                                    </button>

                                    <div ref={WaveContainerRef} className='central-surfer-component'>
                                        <div className='main-surfer-line'/>
                                    </div>
                                </div>
                            )
                        )}
                        {
                            (!loading && messageId !== undefined && !isActuallyUser && !isContinuing &&
                                (window.innerWidth < 680 || isHovered || 'messageIndex' < 5)) && (
                                <div
                                    className={`selection-menu-general ${window.innerWidth < 680 || isHovered || 'messageIndex' < 5 ? 'fadeIn' : ''}`}>
                                    <MessageButtonSelection
                                        className="selection-menu-general"
                                        deleteEnabled={isLatest && !isFirst}
                                        regenerateEnabled={isLatest && !isFirst}
                                        imageEnabled={isFirst ? false : imageActionDisabled}
                                        continueEnabled={isLatest && !isFirst}
                                        messageId={getMessageId}
                                        generateImage={generateImage}
                                        loading={loading}
                                        setLoading={setLoading}
                                        hasImage={hasImage}
                                        regenerateMessage={regenerateMessage}
                                        isLatest={isLatest}
                                        deleteMessage={deleteMessageWrapper}
                                        handleContinueMessage={handleContinueMessage}
                                        handleGenerateAudio={generateAudio}

                                        hasAudio={hasAudio}
                                        audioEnabled={audioEnabled}
                                    />
                                </div>
                            )
                        }
                    </div>


                    {subscriptionError && <UpgradeNotification isOpen={subscriptionError} onClose={(confirm) => {
                        setSubscriptionError(false);
                        if (confirm) {
                            navigate('/pricing');
                        }
                    }}/>}


                </div>
                {imageLoading && imageURL.length === 0 ? (<div ref={loadingRef}
                                                               className="loading-image-container">
                    <svg width="100" height="100" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="45" strokeWidth="10" fill="none" stroke="#242424"/>
                        <circle cx="50" cy="50" r="45" strokeWidth="10" fill="none" stroke="#916DE8"
                                strokeDasharray={Math.PI * 2 * 45}
                                strokeDashoffset={Math.PI * 2 * 45 * ((100 - progress) / 100)}
                                style={{transition: 'stroke-dashoffset 0.3s linear'}}
                        />
                        <text x="50" y="55" textAnchor="middle" fill="#916DE8"
                              className="loading-text">{`${progress}%`}</text>
                    </svg>

                    <div className="loading-image-text">
                        Please Wait!
                    </div>

                    <div className="loading-image-text-2">
                        Your image is being generated.
                    </div>


                </div>) : null}

                {!isActuallyUser && imageURL && (
                    <div
                        className="ai-chat-message-image"
                        style={{position: 'relative', pointerEvents: "visible"}}
                        onClick={(e) => {
                            if (e.target.classList.contains('chat-image') || e.target.classList.contains('ai-chat-message-image')) {
                                const imageElement = e.currentTarget.querySelector('.chat-image');
                                openImageInFullScreen(imageElement);
                            }
                        }}
                    >
                        {loading && (
                            <div className="loading-button">
                            </div>
                        )}


                        {!imageLoaded &&
                            <>
                                {profileData.subscription_data.tier !== 'FREE' ? (

                                    <div className="loading-image-container-3" onContextMenu={(e) => e.preventDefault()}
                                         style={{userSelect: 'none', WebkitUserSelect: 'none'}}>
                                        <div className='view-image-premium-button' style={{
                                            height: '120px',
                                            width: '40%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <BounceLoader/>
                                        </div>
                                    </div>

                                ) : (

                                    <div className="loading-image-container-2" onContextMenu={(e) => e.preventDefault()}
                                         style={{userSelect: 'none', WebkitUserSelect: 'none'}}>
                                        <img src={currentImage} alt="" className="anime-image" draggable="false"
                                             onContextMenu={(e) => e.preventDefault()}/>
                                        <button
                                            className='view-image-premium-button'
                                            onClick={handleRemoveBlurClick}
                                        >
                                            <DiamondIcon className='view-image-diamond-icon'/>
                                            Remove Blur
                                        </button>
                                    </div>
                                )}


                            </>
                        }

                        <div className="image-container" style={{overflow: 'hidden', borderRadius: '0 14px 14px 14px'}}>

                            <img
                                className={`chat-image ${imageLoaded ? 'visible' : 'hidden'} ${isFullscreen ? 'fullscreen' : ''} ${cacheLikeStatus === 3 ? 'blur' : ''}`}
                                src={`https://elyza.app/cdn-cgi/image/format=avif,width=832,height=1216,compression=fast/${imageURL}`}
                                alt=""
                                onLoad={handleImageLoaded}
                                onError={(e) => console.error('Image failed to load:', e)}
                            />

                            {cacheLikeStatus === 3 && (
                                <button
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        zIndex: 0,

                                    }}
                                    className='view-image-premium-button'
                                    onClick={handleRemoveBlurClick}
                                >
                                    <DiamondIcon className='view-image-diamond-icon'/>
                                    Remove Blur
                                </button>
                            )}
                        </div>

                        {imageLoaded && ((isMobile && imageLoaded) || (cacheLikeStatus !== 3)) && (


                            <div className="like-dislike-buttons"
                                 style={{position: 'absolute', top: '5px', left: '5px'}}>

                                <div className={`like-icon ${cacheLikeStatus === 1 ? 'icon-like-selected' : ''}`}
                                     onClick={handleLikeClick}>
                                    <ThumbsUpIcon/>
                                </div>

                                <div className={`dislike-icon ${cacheLikeStatus === 2 ? 'icon-like-selected' : ''}`}
                                     onClick={handleDislikeClick}>
                                    <ThumbsDownIcon/>
                                </div>

                            </div>
                        )}
                    </div>
                )}

            </div>

            <ReportDislikeButton
                isOpen={isReportModalOpen}
                onCancel={() => setReportModalOpen(false)}
                imageUrl={imageURL}
                reportNegativeImage={reportNegativeImageQuality}
                shortDescription={reportFormMessage}
                setShortDescription={setReportFormMessage}
                handleShortDescriptionChange={handleShortDescriptionChange}
            />
        </div>
    );
};

export default AIOutputChat;
